<h1 mat-dialog-title></h1>
<div mat-dialog-content>
  <form [formGroup]="userForm">
    <div class="row" *ngIf="!otpResponse; else response">
      <div class="col-sm-12 col-md-4 col-lg-4">
        <mat-form-field>
          <mat-select formControlName="countryCode" placeholder="country">
            <div *ngFor="let code of countryData">
              <mat-option [value]="code.code" *ngIf="code.code">
                {{code.code}}
              </mat-option>
            </div>
          </mat-select>
          <mat-error *ngIf="!userForm.get('countryCode').valid"> Country is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-8">
        <mat-form-field>
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phone" placeholder="Phone" required>
          <mat-error *ngIf="!userForm.get('phone').valid">Please Enter valid Phone
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <ng-template #response>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <p>{{ otpResponse }}</p>
          <mat-form-field>
              <input matInput type="text" placeholder="Enter OTP Here" formControlName="code">
              <button [disabled]="timer > 0" mat-button matSuffix cdkFocusInitial (click)="sendOTP()">Resend ({{ timer }})</button>
          </mat-form-field>
        </div>
      </div>
    </ng-template>
  </form>
</div>
<div>
  <button mat-button class="float-left" (click)="onNoClick()">cancel</button>
  <!--button *ngIf="!code" mat-button cdkFocusInitial (click)="sendOTP()">resend?</button-->
  <button *ngIf="otpResponse; else sendOTPBtn" [disabled]="userForm.get('code').value.length == 0" class="float-right" mat-button cdkFocusInitial (click)="submit()">Verify</button>
  <ng-template #sendOTPBtn>
    <button [disabled]="!userForm.valid" class="float-right" mat-button cdkFocusInitial (click)="sendOTP()">Send OTP</button>
  </ng-template>
</div>