export class GlobalConstants {
    public static countryCodes = [{
      "currency": "USD",
      "code": "+1",
      "usd": "$",
      "name": "United States"
    },
    {
      "currency": "INR",
      "code": "+91",
      "inr": "₹",
      "name": "India"
    },
    {
      "currency": "NZD",
      "code": "+64",
      "inr": "$",
      "name": "New Zealand"
    }];

    public static templates = [{
      name: 'Italian',
      template: 'italian.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/italian-pizza-orange.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: "Sleek, Sophisticated, Mature, & Formal"
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: "Sleek, Sophisticated, Mature, & Formal"
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: "Sleek, Sophisticated, Mature, & Formal"
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: "Sleek, Sophisticated, Mature, & Formal"
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'American Steakhouse',
      template: 'american-steakhouse.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/american-steakhouse.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }, {
      name: 'Indian Restaurant',
      template: 'indian-restaurant.ejs',
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/layouts/indian-restaurant.png',
      tags: "",
      colorPalette: [{
        primary: '#4736ca',
        secondary: '#27224f',
        tertiary: '#000',
        name: ""
      }, {
        primary: '#00cc47',
        secondary: '#041029',
        tertiary: '#0065ff',
        name: ""
      }, {
        primary: '#297afb',
        secondary: '#2898fb',
        tertiary: '#01d8fd',
        name: ""
      }, {
        primary: '#353740',
        secondary: '#35bac1',
        tertiary: '#3ad2ad',
        name: ""
      }, {
        primary: '#422db1',
        secondary: '#252780',
        tertiary: '#b061d0',
        name: ""
      }, {
        primary: '#267b70',
        secondary: '#33a07f',
        tertiary: '#8bd746',
        name: ""
      }]
    }];

    public static subscriptions_USA = [{
      name: "BASIC",
      price: "Free",
      planId: "011",
      frequency: "14 days trial",
      features: [{
          enable: true,
          text: "Email Marketing Module"
        }, {
          enable: true,
          text: "User Control Management"
        }, {
          enable: false,
          text: "List Building And Cleaning"
        }, {
          enable: false,
          text: "Collected Data Reports"
        }, {
          enable: false,
          text: "Planning And Evaluation"
        }]
    }, {
      name: "ADVANCED",
      price: "29.99",
      planId: "012",
      frequency: "monthly",
      features: [{
        enable: true,
        text: "Email Marketing Module"
      }, {
        enable: true,
        text: "User Control Management"
      }, {
        enable: true,
        text: "List Building And Cleaning"
      }, {
        enable: false,
        text: "Collected Data Reports"
      }, {
        enable: false,
        text: "Planning And Evaluation"
      }]
    }, {
      name: "COMPLETE",
      price: "39.99",
      planId: "012",
      frequency: "monthly",
      features: [
        {
          enable: true,
          text: "Email Marketing Module"
        }, {
          enable: true,
          text: "User Control Management"
        }, {
          enable: true,
          text: "List Building And Cleaning"
        }, {
          enable: true,
          text: "Collected Data Reports"
        }, {
          enable: true,
          text: "Planning And Evaluation"
        }]
    }];

    public static subscriptions_India = [{
      name: "BASIC",
      price: "Free",
      planId: "plan_GBt6Ph2qk9jEq8",
      frequency: "14 days trial",
      features: [{
          enable: true,
          text: "Email Marketing Module"
        }, {
          enable: true,
          text: "User Control Management"
        }, {
          enable: false,
          text: "List Building And Cleaning"
        }, {
          enable: false,
          text: "Collected Data Reports"
        }, {
          enable: false,
          text: "Planning And Evaluation"
        }]
    }, {
      name: "ADVANCED",
      price: "29.99",
      planId: "plan_GBt6Ph2qk9jEq8",
      frequency: "monthly",
      features: [{
        enable: true,
        text: "Email Marketing Module"
      }, {
        enable: true,
        text: "User Control Management"
      }, {
        enable: true,
        text: "List Building And Cleaning"
      }, {
        enable: false,
        text: "Collected Data Reports"
      }, {
        enable: false,
        text: "Planning And Evaluation"
      }]
    }, {
      name: "COMPLETE",
      price: "39.99",
      planId: "plan_GBt6Ph2qk9jEq8",
      frequency: "monthly",
      features: [
        {
          enable: true,
          text: "Email Marketing Module"
        }, {
          enable: true,
          text: "User Control Management"
        }, {
          enable: true,
          text: "List Building And Cleaning"
        }, {
          enable: true,
          text: "Collected Data Reports"
        }, {
          enable: true,
          text: "Planning And Evaluation"
        }]
    }]

    public static menuServices_USA = ["GrubHub", "Doordash"];

    public static menuServices_India = ["Swiggy"];

    public static deliveryPartners_USA = [{
      name: "GrubHub",
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/partners/grubhub.jpg'
    }, {
      name: "Doordash",
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/partners/doordash.jpg'
    }];

    public static deliveryPartners_India = [{
      name: "Swiggy",
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/partners/swiggy.jpg'
    }, {
      name: "Zomato",
      imageUrl: 'https://gqw-qa.s3.amazonaws.com/assets/saas/ejs/templates/partners/zomato.jpg'
    }];
}