import { Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class PhoneService {
  constructor(private http: HttpClient) {
  };

  sendPicCode(body) {
    return this.http.post('/auth/otp', body, {headers: new HttpHeaders({
      aggregated: 'true'
      })});
  }

  VerifyPicCode(body) {

    return this.http.post('/auth/otp/verify', body, {headers: new HttpHeaders({
      aggregated: 'true'
      })});
  }
}
