import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhoneService } from '../core/services/phone-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

export interface DialogData {
    input: string;
    inputType: string;
    verified: boolean;
}

@Component({
    selector: 'otp-check-dialog',
    styleUrls: ['otp-check-dialog.scss'],
    templateUrl: 'otp-check-dialog.html',
  })

export class OtpCheckDialog {

    timer = 30;
    otpResponse;
    interval;
    userForm: FormGroup;
    countryData = [
      {
        "code": "+1",
      },
      {
        "code": "+91",
      },
      {
        "code": "+64",
      }
    ];
    constructor(public dialogRef: MatDialogRef<OtpCheckDialog>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public phoneService: PhoneService,
                private _formBuilder: FormBuilder) {

        const phoneRegex = '^[0-9]{10}$';
        this.userForm = this._formBuilder.group({
          'countryCode': ['', Validators.required],
          'phone': ['', [Validators.required, Validators.pattern(phoneRegex)]],
          'code': ['']
        });

        // this.sendOTP();
    }

    get formData() {
      const obj = this.userForm.value;
      return {...obj, ...({internationalPhone: obj.countryCode + obj.phone})};
    }

    onNoClick(): void {
      this.dialogRef.close(this.data);
    }

    submit() {
        const body = {
          applink: 'www.grabqpons.com'
        }
        body[this.data.inputType] = this.formData.internationalPhone;
        body['otp_code'] = this.formData.code;

        this.phoneService.VerifyPicCode(body).subscribe((verifiedres) => {
            if (verifiedres['success']) {
                this.data.verified = true;
                this.dialogRef.close(this.data);
            }
        });
    }

    sendOTP() {
        const body = {};
        body[this.data.inputType] = this.formData.internationalPhone;
        this.phoneService.sendPicCode(body).subscribe((res) => {
            if (res['success']) {
              this.otpResponse =  `One Time Password (OTP) has been sent to your ${this.data.inputType} - ${this.data.input}, Please enter OTP here to verify your ${this.data.inputType}.`;
              this.startTimer();
            }
        });
    }

    startTimer() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.timer = 30;
      this.interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer = this.timer - 1;
        }
      }, 1000);
    }
}