import { Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Injectable()
export class RestaurantService {
  constructor(private http: HttpClient) {
  };

  businessInfo(body) {
    return this.http.post('/saas/web/register-business/BUSINESS_INFO', body, {headers: new HttpHeaders({
      aggregated: 'true',
      isinternal: 'true'
    })});
  }

  preBuild(body) {
    return this.http.post('/saas/web/register-business/PRE_BUILD', body,
    {   headers: new HttpHeaders({
        aggregated: 'true',
        isinternal: 'true'})
    });
  }

  preview(body) {
    return this.http.post('/saas/web/register-business/PREVIEW', body,
    {   headers: new HttpHeaders({
        aggregated: 'true',
        isinternal: 'true'})
    });
  }

  finalize(body) {
    return this.http.post('/saas/web/register-business/FINALIZE', body,
    {   headers: new HttpHeaders({
        aggregated: 'true',
        isinternal: 'true'})
    });
  }

  searchMenu(body) {
    return this.http.post('/saas/web/register-business/SEARCH_MENU', body, { headers: new HttpHeaders({
      isinternal: 'true',
      aggregated: 'true'
    })
    });
  }

  search(terms: Observable<string>, currency) {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => term.length ? this.domainCheck(term, currency) : of({}))
    ) 
  }
  
  domainCheck(term, currency) {
    const body = {
      'searchKey': term,
      'provider': 'godaddy',
      'country': currency
    };
    return this.http.post('/saas/web/register-business/DOMAIN_CHECK', body, { headers: new HttpHeaders({
      isinternal: 'true',
      aggregated: 'true'
    })
    });
  }

  getPaymentToken(userId) {
    return this.http.get('/payments/token/' + userId);
  }

}
